<template>
  <div class="ui form">
    <a class="text-size-md" href="/partners/dashboard"><< back to dashboard</a>
    <div class='button right' @click="onSubmit" id="saveButton">Save</div>

    <div class="pl48 pr48">
      <div class='mt56 mb56'>
        <input v-model="slug" class='form-control' placeholder="your-brand-name" id="linkSlugInput">
        <div id="slug-error">(no spaces or special characters)</div>
        <div class="mt16 text-size-sm text-purple">
          referral url: https://fomo.com/go/{{slug}}
        </div>
      </div>
      <div class='mt36 mb56'>
        <div class="ui grid">
          <div class="twelve wide column">
            <div class="text-purple mb24">FREE TRIAL LENGTH</div>
            <vue-slider v-model="slider.value" v-bind="slider"></vue-slider>
          </div>
          <div class="four wide column text-center">
            <div class="text-purple mb12">YOUR INCENTIVE</div>
            <div class="text-center">
              <div class="text-extra-bold text-purple text-size-sm">{{incentive.value[slider.data.indexOf(slider.value)]}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import vueSlider from 'vue-slider-component';
import api from '../api/resources';

export default {
  el: "#LinkNew",
  components: { vueSlider },
  data: function () {
    return {
      slug: "",
      slider: {
        value: '14 days',
        processStyle: { "backgroundColor": "#E79C6D" },
        tooltipStyle: {
          "padding": "5px 15px",
          "font-size": "18px",
          "border": "1px solid #e79c6d",
          "background-color": "#e79c6d"
        },
        tooltipDir: "bottom",
        data: [
          "First Month Free",
          "Two Months Free",
          "Three Months Free",
        ]
      },
      incentive: {
        value: [
        '25% Revenue Share',
        '15% Revenue Share',
        '10% Revenue Share'
        ]
      }
    }
  },

  methods:{
    onSubmit(){
      if ( this.slug.indexOf(' ') >= 0 || this.slug.length == 0){
        $('#slug-error').addClass('text-red text-size-sm');
        return false;
      } else {
        $('#slug-error').removeClass('text-red');
      }

      var data = {
        link:{
          slug: this.slug,
          promo: this.slider.value
        }
      }

      api.createLink(data).then(response => {
        toastr.success('Link saved');
        window.location.href = "/partners/links/" + response.body.id + "/confirmation";
      }, response => {
        toastr.error('Link not saved, it may already exist. Try again.');
      });

    }
  }

}

</script>

<style scoped>
</style>
