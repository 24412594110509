import Vue from 'vue'
import VueResource from 'vue-resource'
import {API_ROOT} from '../config'

Vue.use(VueResource)
Vue.http.options.crossOrigin = true
Vue.http.headers.common['X-CSRF-Token'] = $('[name="csrf-token"]').attr('content');

const LinkResource = Vue.resource('/partners/links.json')

export default {
  getLinks: function () {
    return LinkResource.get({id:''})
  },
  getLink: function (id) {
    return LinkResource.get({id: id})
  },
  createLink: function (data) {
    return LinkResource.save(data)
  },
  deleteLink: function (id) {
    return Vue.resource("/links/"+id+".json").delete()
  }
}
